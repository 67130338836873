import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":_vm.props.width},on:{"click:outside":function($event){return _vm.close()}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,{attrs:{"color":_vm.props.color,"dark":_vm.props.dark}},[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.props.title)+" ")]),_c(VCardText,{staticStyle:{"white-space":"pre"}},[_vm._v(" "+_vm._s(_vm.props.text)+" ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"small":"","text":""},on:{"click":_vm.close}},[_vm._v("OK")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }