<template>
    <div class="pa-5">
        <v-card>
            <v-card-title>
                Business Day/Time Settings
            </v-card-title>
            <v-divider></v-divider>
            <v-list dense>
                
                <v-list-item v-for="d in business_days" :key="d.id">
                    <v-list-item-action>
                        <v-btn icon>
                            <v-checkbox :input-value="d.active" v-model="d.active"></v-checkbox>
                        </v-btn>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-row disabled>
                            <v-col cols="12" sm="8">
                                <v-text-field readonly dense 
                                    outlined 
                                    hide-details 
                                    label="Day of the Week"
                                    v-model="d.day"
                                    :disabled="!d.active">
                                </v-text-field>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col cols="12" sm="2">
                                <v-select hide-details dense 
                                    label="From"
                                    outlined
                                    :disabled="!d.active"
                                    v-model="d.start_time"
                                    :items="times_of_day" 
                                    item-value="time" 
                                    item-text="text">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="2">
                                <v-select hide-details dense 
                                    label="To"
                                    outlined
                                    :disabled="!d.active"
                                    v-model="d.end_time"
                                    :items="times_of_day" 
                                    item-value="time" 
                                    item-text="text">
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="saveSettings()" :disabled="$store.state.loading">
                    <v-icon>{{ $store.state.loading ? 'mdi-loading mdi-spin' : 'mdi-check' }}</v-icon> Save
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-snackbar
            v-model="snackbar"
            timeout="3000"
            centered
            class="text-center"
            >
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="red"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                    >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
    import axios from 'axios';
    import {globalMixins} from '@/mixins/globalMixins.js'
    export default {
        name : 'BusinessTime',
        mixins : [globalMixins],
        data() {
            return {
                business_days : [],
                snackbar : false,
                snackbar_text : ''
            }
        },
        mounted() {
            axios({
                url : this.$store.state.api_url +  '/business-times',
                method : 'GET',
                headers : {
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(response => {
                this.business_days = response.data;
            })
        },
        methods: {
            saveSettings() {
                this.$store.dispatch('loading', true);
                axios({
                    url : this.$store.state.api_url + '/business-times',
                    method : 'POST',
                    data : {business_times : this.business_days},
                    headers : {
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(response => {
                    this.snackbar = true;
                    this.snackbar_text = 'Updated successfully!';
                    this.$store.dispatch('loading', false);
                }).catch(err => {
                    this.snackbar_text = 'Something went wrong.';
                    this.$store.dispatch('loading', false);
                })
            }
        }
    }
</script>