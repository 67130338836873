<template>
    <div>
        <v-list dense subheader>
            <slot name="subtitle"></slot>
            <v-list-item>
                <v-list-item-content>
                    <v-text-field v-model="search" label="Search" dense hide-details outlined></v-text-field>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item v-for="s in filteredList" v-bind:key="s.id">
                <v-list-item-content>
                    <v-list-item-title>{{ s.name }} ({{ s.email }})</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                    <v-btn x-small :disabled="$store.state.loading" @click="select(s.id)" color="indigo" class="white--text">
                        Ask To Check
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <v-overlay :value="$store.state.loading">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
    export default {
        name : 'CheckerList',
        props : ['staffs'],
        data () {
            return {search : ''}
        },
        methods : {
            select(id) {
                this.$emit('assignChecker', id);
            }
        },
        computed: {
            filteredList() {
                return this.staffs.filter(staff => {
                    return staff.name.toLowerCase().includes(this.search.toLowerCase())
                })
            }
        }
    }
</script>
