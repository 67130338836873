<template>
    <v-card>
        <v-card-text class="grey lighten-2 text-uppercase pa-3">
            <strong>CREATE COMPANY RECORD</strong>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
            <div class="pt-3">
                <v-text-field label="Company Name" v-model="company.company_name" dense hide-details outlined></v-text-field>
            </div>
            <div class="pt-3">
                <v-textarea label="Company Address" v-model="company.address" dense hide-details outlined></v-textarea>
            </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn small block color="primary" @click="saveCompany()">
                save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name : 'CompanyDetails',
    props : ['company'],
    data() {
        return {
        };
    },
    methods: {
        saveCompany() {
            this.$emit('save', this.company);
        }
    }
}
</script>