<template>
    <div>
        <v-row no-gutter>
            <v-col cols="12" sm="8">
                <div class="pa-5">
                    <div>
                        <h3><v-icon>mdi-package-check</v-icon> Job-000000001</h3>
                    </div>
                    <div class="text-sm mt-2">
                        <v-icon class="text-sm">mdi-map-marker</v-icon> 60 Wharf St, Mount Elliot, New South Wales, AU
                    </div>
                    <div class="text-sm mt-2">
                        <v-icon class="text-sm">mdi-calendar</v-icon> 01 Jan 2022 01:00PM
                    </div>
                    <div class="text-sm mt-2">
                        <v-icon class="text-sm">mdi-account-box</v-icon> Submitted by Rolly Domingo
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="pl-5 pr-5">
                    <v-timeline dense>
                        <v-timeline-item
                            v-for="(item, i) in items"
                            :key="i"
                            :color="item.color"
                            :icon="item.icon"
                        >
                            <v-card dark dense class="elevation-2 darken-2" :color="item.color">
                                <v-card-title class="pt-1 pb-1">
                                    {{ item.status }}
                                </v-card-title>
                                <v-card-text>
                                    <div>
                                        {{ item.message }}
                                    </div>
                                    <v-row class="text-xs mt-1">
                                        <v-col>
                                            <v-icon class="text-xs">mdi-calendar</v-icon> 01 Jan 2022 01:00PM
                                        </v-col>
                                        <v-col class="text-right">
                                            <v-icon class="text-xs">mdi-account</v-icon> Rolly Domingo
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-timeline-item>
                    </v-timeline>
                </div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" sm="4" class="full-height">
                <v-list dense>
                    <v-subheader>JOBS LIST</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item two-line class="with-border" v-for="j in jobs" v-bind:key="j.id">
                        <v-list-item-content>
                            <v-list-item-title>{{ j.job_number }}</v-list-item-title>
                            <v-list-item-subtitle>{{ j.job_address }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>
    </div>
</template>
<style scoped>
    .full-height {
        height : 100vh;
    }
    .text-sm {
        font-size : 14px;
    }
    .text-xs {
        font-size : 13px;
    }
    .with-border {
        border-bottom : 1px solid rgba(0, 0, 0, 0.12);
    }
</style>
<script>
    export default {
        name : 'JobDetails',
        data() {
            return {
                jobs : [
                    {
                        id : 1, 
                        job_number : 'J-000000001',
                        job_address : '60 Wharf St, Mount Elliot, New South Wales, AU'
                    },
                    {
                        id : 2, 
                        job_number : 'J-000000002',
                        job_address : '26 Girvan Grove, Eaglehawk, Victoria, AU'
                    }
                ],
                items : [
                    {
                        color : 'green',
                        icon : 'mdi-card-multiple-outline',
                        status : 'Allocated',
                        message : 'Job has been submitted by Rolly Domingo and waiting to be accepted',
                        time : '01 Jan 2021 08:00 AM'
                    },
                    {
                        color : 'green',
                        icon : 'mdi-book-arrow-left',
                        status : 'Accepted',
                        message : 'Job has been accepted by Genesis Merioles',
                        time : '01 Jan 2021 08:00 AM'
                    },
                    {
                        color : 'green',
                        icon : 'mdi-clock',
                        status : 'Processed',
                        message : 'Job has been processed.',
                        time : '01 Jan 2021 08:00 AM'
                    },
                    {
                        color : 'green',
                        icon : 'mdi-book-search-outline',
                        status : 'Submitted for Checking',
                        message : 'Job has been submitted for checking',
                        time : '01 Jan 2021 08:00 AM'
                    },
                    {
                        color : 'green',
                        icon : 'mdi-file-chart-check',
                        status : 'Checked',
                        message : 'Job has been checked.',
                        time : '01 Jan 2021 08:00 AM'
                    },
                    {
                        color : 'green',
                        icon : 'mdi-send',
                        status : 'Submitted to Customer',
                        message : 'Job has been completed and waiting for client acknowledgement.',
                        time : '01 Jan 2021 08:00 AM'
                    },
                    {
                        color : 'green',
                        icon : 'mdi-check',
                        status : 'Completed',
                        message : 'Job has been processed successfully',
                        time : '01 Jan 2021 08:00 AM'
                    }
                ]
            }
        }
    }
</script>