<template>
    <v-card>
        <v-card-text class="grey lighten-2 pa-3 text-uppercase">
            <strong>{{ company.company_name }} USERS</strong>
        </v-card-text>
        <v-divider></v-divider>
        <v-progress-linear
            v-if="$store.state.loading"
            indeterminate
            color="primary">
        </v-progress-linear>
        <v-card-text class="pa-3">
            <div class="pb-3">
                <v-text-field label="Name" hide-details outlined dense v-model="user.name"></v-text-field>
            </div>
            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field label="Email" hide-details outlined dense v-model="user.email"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-select
                        v-model="user.account_type"
                        :items="['client-manager', 'client-user']"
                        label="Account Type"
                        hide-details 
                        outlined 
                        dense
                    ></v-select>
                </v-col>
            </v-row>
            <v-btn class="mt-3 darken-3 white--text" color="indigo" small block 
                @click="addUser()"
                :disabled="$store.state.loading">
                <v-icon>mdi-floppy</v-icon> Save
            </v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-simple-table>
            <template v-slot:default>
                <thead class="grey lighten-2 white--text">
                    <tr>
                        <th class="text-left">
                            Name
                        </th>
                        <th class="text-left">
                            Email
                        </th>
                        <th class="text-left">
                            Account Type
                        </th>
                        <th style="width: 80px;">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="item, idx in company.users"
                    :key="item.id"
                    >
                        <td>
                            <span v-if="edit_user.id != item.id">{{ item.name }}</span>
                            <v-text-field 
                                class="font-sm"
                                dense
                                hide-details
                                outlined
                                v-if="edit_user.id == item.id" 
                                v-model="edit_user.name">
                            </v-text-field>
                        </td>
                        <td>
                            <span v-if="edit_user.id != item.id">{{ item.email }}</span>
                            <v-text-field 
                                class="font-sm"
                                dense
                                hide-details
                                outlined
                                v-if="edit_user.id == item.id" 
                                v-model="edit_user.email">
                            </v-text-field>
                        </td>
                        <td>
                            <span v-if="edit_user.id != item.id">{{ item.account_type }}</span>
                            <v-select
                                hide-details 
                                outlined 
                                dense
                                v-model="edit_user.account_type"
                                :items="['client-manager', 'client-user']"
                                v-if="edit_user.id == item.id" 
                            ></v-select>
                        </td>
                        <td>
                            <div v-if="edit_user.id != item.id">
                                <v-btn x-small icon color="success" @click="edit(item)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                                <v-btn x-small icon color="error" @click="remove(idx, item.id)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </div>
                            <div v-if="edit_user.id == item.id">
                                <v-btn x-small icon color="primary" @click="update()">
                                    <v-icon>mdi-floppy</v-icon>
                                </v-btn>
                                <v-btn x-small icon color="error" @click="undo()">
                                    <v-icon>mdi-undo</v-icon>
                                </v-btn>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
</template>
<style scoped>
    .text-uppercase {
        text-transform: uppercase;
    }
</style>
<script>
import axios from 'axios';
export default {
    name : 'CompanyUsersComponent',
    props : ['company'],
    data() {
        return {
            user : {},
            edit_user : {}
        }
    },
    methods: {
        addUser() {
            this.$store.state.loading = true;
            axios({
                url : this.$store.state.api_url + '/companies/' + this.company.id + '/users',
                method : 'POST',
                data : this.user,
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                this.$store.state.loading = false;
                this.$emit('refresh', res.data.data);
                this.user = {};
            }).catch(err => {
                alert(err.response.data.errors.join("\n"))
                this.$store.state.loading = false;
            });
        },
        edit(user) {
            this.edit_user = {...user};
        },
        undo() {
            this.edit_user = {};
        },
        update() {
            this.$store.state.loading = true;
            axios({
                url : this.$store.state.api_url  + '/companies/' + this.company.id + '/users/' + this.edit_user.id,
                method : 'PUT',
                data : this.edit_user,
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                this.$emit('refresh', res.data.data);
                this.$store.state.loading = false;
                this.edit_user = {};
            }).catch(err => {
                this.$store.state.loading = false;
            });
        },
        remove(idx, id) {
            if(confirm('Are you sure?')) {
                this.$store.state.loading = true;
                axios({
                    url : this.$store.state.api_url + '/companies/' + this.company.id + '/users/' + id,
                    method : 'DELETE',
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.company.users.splice(idx, 1);
                    this.$emit('refresh', this.company.users);
                    this.$store.state.loading = false;
                    this.edit_user = {};
                }).catch(err => {
                    this.$store.state.loading = false;
                });
            }
        }
    }
}
</script>
