<template>
    <v-card>
        <v-card-title>Holiday Details</v-card-title>
        <v-card-text>
            <v-text-field v-model="holiday.holiday_description" label="Description" outlined dense></v-text-field>
            <v-menu
                v-model="datepicker1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="holiday.holiday_date"
                    label="Holiday Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                ></v-text-field>
                </template>
                <v-date-picker
                    v-model="holiday.holiday_date"
                    @input="datepicker1 = false"
                ></v-date-picker>
            </v-menu>
            <v-select
                :items="types"
                label="Holiday Type"
                v-model="holiday.holiday_type"
                dense
                outlined
            ></v-select>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn block color="primary" small @click="save()">
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import axios from 'axios';

    export default {
        name : 'HolidayDetails',
        props : ['holiday'],
        data(){
            return {
                datepicker1 : false,
                types : [
                    'Regular Non-Working',
                    'Special Non-Working',
                    'Special Working',
                ]
            }
        },
        methods : {
            save() {
                if(this.holiday.id == undefined) {
                    axios({
                        url : this.$store.state.api_url + '/holidays',
                        method : 'POST',
                        data : this.holiday,
                        headers : {
                            Authorization : 'Bearer ' + this.$store.getters.getToken
                        }
                    }).then(res => {
                        this.$emit('update', res.data);
                    })
                }
                else {
                    axios({
                        url : this.$store.state.api_url + '/holidays/' + this.holiday.id,
                        method : 'PUT',
                        data : this.holiday,
                        headers : {
                            Authorization : 'Bearer ' + this.$store.getters.getToken
                        }
                    }).then(res => {
                        this.$emit('update', res.data);
                    })
                }
                
            }
        }
    }
</script>