<template>
    <v-dialog v-model="show" @click:outside="close()" :width="props.width">
        <v-card :color="props.color" :dark="props.dark">
            <v-card-title class="text-h5">
            {{ props.title }}
            </v-card-title>
            <v-card-text  style="white-space: pre">
                {{ props.text }}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="close" small text>OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name : 'Dialog',
        props : ['show', 'props'],
        methods : {
            close() {
                this.$emit('update:show', false);
            }
        }
    }
</script>