<template>
    <el-tiptap
      v-model="text"
      :extensions="extensions"
    />
</template>
<script>
import { ElementTiptap } from 'element-tiptap';
import {
  // necessary extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Image,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  Link
} from 'element-tiptap';

export default {
    name : 'Editor',
    props : ['content'],
    components: { 'el-tiptap' : ElementTiptap },
    watch : {
        text(newVal, oldVal) {
            this.$emit('update', newVal);
        }
    },
    data() {
        return {
            text : this.content,
            extensions : [
                new Doc(),
                new Text(),
                new Paragraph(),
                new Heading({ level: 5 }),
                new Bold({ bubble: true }), // render command-button in bubble menu.
                new Underline({ bubble: true, menubar: false }), // render command-button in bubble menu but not in menubar.
                new Italic(),
                new Strike(),
                new ListItem(),
                new BulletList(),
                new OrderedList(),
                new Image(),
                new Link()
            ]
        }
    },
    methods: {
        update() {
            console.log(this.text);
        }
    }
}
</script>