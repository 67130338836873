import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"login-bg",attrs:{"cols":"12","sm":"8","md":"9"}}),_c(VCol,{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('div',{staticClass:"pa-5"},[_c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":require("@/assets/logo.png"),"alt":"","height":"80"}}),_c('div',{staticClass:"text-center mt-3"},[_c('h3',[_vm._v("LUNTIAN")]),_c('div',[_vm._v("DESIGN & SOLUTIONS")])])])]),_c(VDivider),_c('div',{staticClass:"pa-5"},[_c('div',{staticClass:"text-center mt-2 mb-3"},[_c('h3',[_vm._v("Log-In")])]),(_vm.login_error.failed)?_c(VAlert,{attrs:{"dense":"","outlined":"","type":"error"},on:{"click":function($event){_vm.login_error.failed = false}}},[_vm._v(" "+_vm._s(_vm.login_error.message)+" ")]):_vm._e(),_c(VTextField,{attrs:{"color":"indigo","outlined":"","dense":"","label":"Email","type":"email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c(VTextField,{attrs:{"color":"indigo","outlined":"","dense":"","label":"Password","type":"password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c(VBtn,{staticClass:"darken-3 white--text",attrs:{"block":"","color":"indigo","disabled":_vm.$store.state.loading},on:{"click":function($event){return _vm.login()}}},[_vm._v("Log-In")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }