<template>
    <div class="pa-5">
        <v-row>
            <v-col class="text-right mb-3">
                <v-btn small color="primary" @click="add()">
                    <v-icon>mdi-plus</v-icon>
                    Request for Leave
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="leaves"
            class="elevation-1"
        >
            <template v-slot:item="{item}">
            <tr>
                <td>{{ item.leave_date_formatted }}</td>
                <td>{{ item.leave_type }}</td>
                <td>{{ item.leave_reason }}</td>
                <td>
                    <span v-if="item.approved == 1">Approved</span>
                    <span v-if="item.declined == 1" :title="item.approver_remarks">
                        Declined
                    </span>
                    <span v-if="item.declined == 0 && item.approved == 0">Pending</span>
                </td>
                <td>
                    <v-btn x-small icon color="red" @click="cancel(item)">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </td>
            </tr>
            </template>
        </v-data-table>

        <v-dialog width="400" v-model="show_leave_details">
            <v-card>
                <v-card-title>Leave Details</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-5">
                    <v-select label="Leave Type" outlined dense 
                        :items="leave_types"
                        v-model="leave.leave_type">
                    </v-select>
                    <v-textarea dense outlined label="Reason"
                        v-model="leave.leave_reason"></v-textarea>

                    <div class="mb-3"
                        v-for="(d, idx) in leave.dates"
                        :key="d">
                        <v-menu
                            v-model="d.menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="d.date"
                                    label="Leave Date"
                                    append-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    dense
                                    hide-details
                                ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="d.date"
                            @input="d.menu = false"
                            ></v-date-picker>
                        </v-menu>
                        <div class="text-right">
                            <v-btn color="error" x-small @click="removeDate(idx)">remove</v-btn>
                        </div>
                    </div>
                    <v-btn x-small @click="addDate()">Add Date</v-btn>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn block small @click="submitLeaveRequest()" color="primary">Submit For Approval</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
export default {
    name : 'LeavesView',
    data() {
        return {
            menu1 : null,
            show_leave_details : false,
            leaves : [],
            leave : {},
            leave_types : ['Personal', 
                'Vacation' ,
                'Sick', 
                'Maternity', 
                'Gynecological', 
                'Paternity', 
                'Parental Leave - Solo Parent Act',
                '10 Days Leave - Women\'s Protection',
                'Bereavement'
            ],
            headers : [
                {
                    text: 'Date',
                    align: 'start',
                    sortable: true,
                    value: 'leave_date'
                },
                {
                    text: 'Leave Type',
                    align: 'start',
                    sortable: true,
                    value: 'leave_type'
                },
                {
                    text: 'Reason',
                    align: 'start',
                    sortable: true,
                    value: 'leave_reason'
                },
                {
                    text: 'Status',
                    align: 'start',
                    sortable: true,
                    value: 'status'
                },
                {
                    text: 'Options',
                    align: 'start',
                    sortable: true,
                },
            ],
        }
    },
    mounted() {
        this.refreshLeaves();
    },
    methods : {
        addDate(){
            this.leave.dates.push({menu : null, date : moment().format('YYYY-MM-DD')});
        },
        refreshLeaves() {
            this.$store.state.loading = true;
            axios({
                url : process.env.VUE_APP_API_URL + '/my/leave-requests',
                method : 'GET',
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                let output = res.data;
                this.leaves = output.data.map(d => {
                    d.leave_date_formatted = moment(d.leave_date).format('DD-MMM-YYYY (dddd)');
                    return d;
                });
                this.$store.state.loading = false;
            }).catch(err => {
                this.$store.state.loading = false;
            });
        },
        add() {
            this.leave = {
                dates : [{
                    menu : null,
                    date : moment().format('YYYY-MM-DD')
                }]
            };
            this.show_leave_details = true;
        },
        cancel(leave) {
            if(confirm("Are you sure you want to cancel this leave?")) {
                axios({
                    url : process.env.VUE_APP_API_URL + '/my/leave-requests/' + leave.id,
                    method : 'DELETE',
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.refreshLeaves();
                })
            }
        },
        submitLeaveRequest() {
            this.$store.state.loading = true;
            axios({
                url : process.env.VUE_APP_API_URL + '/my/leave-requests',
                method : 'POST',
                data : this.leave,
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                let output = res.data;

                this.refreshLeaves();

                this.$store.state.loading = false;
                this.show_leave_details = false;
            }).catch(err => {
                this.$store.state.loading = false;
            });
        },
        removeDate(idx) {
            if(confirm('Are you sure?')) {
                this.leave.dates.splice(idx, 1);
            }
        }
    }
}
</script>
<style>
    .pointer {
        cursor: pointer;
    }
    .text-sm {
        font-size: 10px;
    }
</style>