<template>
    <div>
        <v-list dense subheader>
            <slot name="subtitle"></slot>
            <v-divider></v-divider>
            <v-list-item v-for="s in staffs" v-bind:key="s.id">
                <v-list-item-content>
                    <v-list-item-title>{{ s.name }} ({{ s.email }})</v-list-item-title>
                    <v-list-item-subtitle>
                        <span :title="s.assigned_jobs_preview">{{ s.assigned_jobs.length }} Active Job(s)</span>
                    </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                    <v-btn x-small :disabled="$store.state.loading" @click="assign(s.id)" color="indigo" class="white--text">
                        Assign
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <v-overlay :value="$store.state.loading">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
    export default {
        name : 'StaffList',
        props : ['staffs'],
        methods : {
            assign(id) {
                this.$emit('assignStaff', id);
            }
        }
    }
</script>
