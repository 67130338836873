<template>
    <div class="pa-5">
        <v-card>
            <v-card-text class="grey lighten-3">
                <strong>NOTIFICATIONS</strong>
            </v-card-text>
            <v-divider></v-divider>
            <v-list>
                <v-list-item v-for="n in notifications" :key="n.id" link :to="n.link">
                    <v-list-item-content>
                        <v-list-item-title>{{ n.details }}</v-list-item-title>
                        <v-list-item-subtitle>{{ n.timestamp }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name : 'Notification',
    data () {
        return {
            notifications : []
        }
    },
    mounted() {
        axios({
            url : this.$store.state.api_url + '/notifications',
            method : 'GET',
            headers : {
                'Authorization' : 'Bearer ' + this.$store.getters.getToken
            }
        }).then(res => {
            this.notifications = res.data;
        })
    }
}
</script>