import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardText,{staticClass:"grey lighten-2 text-uppercase pa-3"},[_c('strong',[_vm._v("CREATE COMPANY RECORD")])]),_c(VDivider),_c(VCardText,[_c('div',{staticClass:"pt-3"},[_c(VTextField,{attrs:{"label":"Company Name","dense":"","hide-details":"","outlined":""},model:{value:(_vm.company.company_name),callback:function ($$v) {_vm.$set(_vm.company, "company_name", $$v)},expression:"company.company_name"}})],1),_c('div',{staticClass:"pt-3"},[_c(VTextarea,{attrs:{"label":"Company Address","dense":"","hide-details":"","outlined":""},model:{value:(_vm.company.address),callback:function ($$v) {_vm.$set(_vm.company, "address", $$v)},expression:"company.address"}})],1)]),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"small":"","block":"","color":"primary"},on:{"click":function($event){return _vm.saveCompany()}}},[_vm._v(" save ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }