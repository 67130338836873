import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,{attrs:{"no-gutter":""}},[_c(VCol,{attrs:{"cols":"12","sm":"8"}},[_c('div',{staticClass:"pa-5"},[_c('div',[_c('h3',[_c(VIcon,[_vm._v("mdi-package-check")]),_vm._v(" Job-000000001")],1)]),_c('div',{staticClass:"text-sm mt-2"},[_c(VIcon,{staticClass:"text-sm"},[_vm._v("mdi-map-marker")]),_vm._v(" 60 Wharf St, Mount Elliot, New South Wales, AU ")],1),_c('div',{staticClass:"text-sm mt-2"},[_c(VIcon,{staticClass:"text-sm"},[_vm._v("mdi-calendar")]),_vm._v(" 01 Jan 2022 01:00PM ")],1),_c('div',{staticClass:"text-sm mt-2"},[_c(VIcon,{staticClass:"text-sm"},[_vm._v("mdi-account-box")]),_vm._v(" Submitted by Rolly Domingo ")],1)]),_c(VDivider),_c('div',{staticClass:"pl-5 pr-5"},[_c(VTimeline,{attrs:{"dense":""}},_vm._l((_vm.items),function(item,i){return _c(VTimelineItem,{key:i,attrs:{"color":item.color,"icon":item.icon}},[_c(VCard,{staticClass:"elevation-2 darken-2",attrs:{"dark":"","dense":"","color":item.color}},[_c(VCardTitle,{staticClass:"pt-1 pb-1"},[_vm._v(" "+_vm._s(item.status)+" ")]),_c(VCardText,[_c('div',[_vm._v(" "+_vm._s(item.message)+" ")]),_c(VRow,{staticClass:"text-xs mt-1"},[_c(VCol,[_c(VIcon,{staticClass:"text-xs"},[_vm._v("mdi-calendar")]),_vm._v(" 01 Jan 2022 01:00PM ")],1),_c(VCol,{staticClass:"text-right"},[_c(VIcon,{staticClass:"text-xs"},[_vm._v("mdi-account")]),_vm._v(" Rolly Domingo ")],1)],1)],1)],1)],1)}),1)],1)],1),_c(VDivider,{attrs:{"vertical":""}}),_c(VCol,{staticClass:"full-height",attrs:{"cols":"12","sm":"4"}},[_c(VList,{attrs:{"dense":""}},[_c(VSubheader,[_vm._v("JOBS LIST")]),_c(VDivider),_vm._l((_vm.jobs),function(j){return _c(VListItem,{key:j.id,staticClass:"with-border",attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(j.job_number))]),_c(VListItemSubtitle,[_vm._v(_vm._s(j.job_address))])],1)],1)})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }