<template>
    <div class="pa-5">
        <v-card>
            <v-card-text class="grey lighten-4">
                <v-row>
                    <v-col cols="12" md="8" lg="9" sm="7">
                        <strong>TIMESHEET</strong>
                    </v-col>
                    <v-col cols="12" md="4" lg="3" sm="5">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="date"
                                label="Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                                dense
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="date"
                            @input="menu = false"
                            :max="current_date"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card>
                <v-simple-table dense>
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Name
                            </th>
                            <th v-for="d in days" :key="d.date" class="text-center text-sm">
                                <div>{{ d.short_date }}</div>
                                <div>{{ d.day_of_week }}</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="s in staffs"
                        :key="s.user.id"
                        >
                        <td>{{ s.user.name }}</td>
                        <td v-for="t in s.day_tasks" 
                            :key="t.date" 
                            class="bordered" 
                            :class="getClass(t.hours)"
                            @click="getTimesheetDetails(s.user, t.date, t.hours)">
                        </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-card>
        <v-dialog width="600" v-model="show_timesheet_details">
            <v-card>
                <v-card-title>
                    <v-row>
                        <v-col>
                            {{ timesheet.name }}
                        </v-col>
                        <v-col class="text-right">
                            {{ timesheet.date }}
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-divider></v-divider>
                <v-divider></v-divider>
                <v-simple-table>
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th>Details</th>
                            <th>Start</th>
                            <th>End</th>
                            <th>Hours</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="t in timesheet.tasks" :key="t.id">
                            <td>{{ t.task_details }}</td>
                            <td>{{ t.start_time_display }}</td>
                            <td>{{ t.end_time_display }}</td>
                            <td>{{ t.total_hours }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="4" class="text-right">
                                <h3>Total: {{ timesheet.hours }}hr(s)</h3>
                            </td>
                        </tr>
                    </tfoot>
                    </template>
                </v-simple-table>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small color="default" @click="show_timesheet_details = false;">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name : 'Notification',
    data () {
        return {
            staffs : [],
            days : [],
            date : null,
            current_date : null,
            menu : null,
            timesheet : {
                name : null,
                date : null
            },
            show_timesheet_details : false,
            list : []
        }
    },
    watch : {
        date(newVal, oldVal) {
            this.loadTimeSheet(newVal)
        }
    },
    mounted() {
        this.current_date = this.formatDate(new Date());
        this.date = this.formatDate(new Date());
    },
    methods : {
        loadTimeSheet(days) {
            axios({
                url : process.env.VUE_APP_API_URL + '/staffs/timesheet',
                method : 'GET',
                params : {
                    date : this.date,
                    days : 14
                },
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                let output = res.data;
                this.days = output.data.days;
                this.staffs = output.data.summary;
            });
        },
        formatDate(date) {
            let year = date.getFullYear();
            let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
            let day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        getClass(hours) {
            if(hours > 0 && hours < 8) {
                return 'orange pointer';
            }
            else if(hours == 8) {
                return 'green pointer';
            }
            else if(hours > 8) {
                return 'red pointer';
            }
        },
        getTimesheetDetails(user, date, hours = 0) {
            if(hours == 0) {
                return;
            };
            this.timesheet = {
                name : user.name,
                date : new Date(date).toDateString(),
                tasks : [],
                hours : 0
            }

            this.show_timesheet_details = true;

            this.$store.state.loading = true;
            axios({
                url : process.env.VUE_APP_API_URL + '/staff/'+ user.id +'/timesheet',
                method : 'GET',
                params : {date : date},
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                let output = res.data;
                this.timesheet.tasks = output.data.tasks.map(d => {
                    d.start_time_display = new Date(d.start_time).toLocaleTimeString();
                    d.end_time_display = new Date(d.end_time).toLocaleTimeString();
                    return d;
                });
                this.timesheet.hours = output.data.total_hours;

                this.$store.state.loading = false;
            }).catch(err => {
                this.$store.state.loading = false;
            });
        }
    }
}
</script>
<style scoped>
    .bordered {
        border-bottom : 1px solid rgba(0, 0, 0, 0.12);
    }
    .bordered:last-child {
        border : 0px;
    }
    .text-sm {
        font-size: 10px !important;
    }
    td.bordered {
        border-left : 1px solid rgba(0, 0, 0, 0.12);
        border-right : 1px solid rgba(0, 0, 0, 0.12) !important;
    }
    .pointer {
        cursor: pointer;
    }
</style>