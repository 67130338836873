<template>
    <div class="pa-5">
        <v-row>
            <v-col class="text-right mb-3">
                <v-btn small color="primary" @click="add()">
                    <v-icon>mdi-plus</v-icon>
                    New Holiday Record
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="holidays"
            class="elevation-1"
        >
            <template v-slot:item="{item}">
            <tr>
                <td>{{ item.date }}</td>
                <td>{{ item.type }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.day_of_week }}</td>
                <td>
                    <v-btn x-small icon color="green" @click="edit(item, item.idx)">
                        <v-icon>mdi-square-edit-outline</v-icon>
                    </v-btn>
                    &nbsp;
                    <v-btn x-small icon color="red" @click="deleteRecord(item)">
                        <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                </td>
            </tr>
            </template>
        </v-data-table>

        <v-dialog v-model="holiday_dialog" width="400">
            <holiday-details :holiday="holiday" @update="update"></holiday-details>
        </v-dialog>
    </div>
</template>
<script>
    import axios from 'axios';
    import HolidayDetails from '@/components/HolidayDetails.vue';
    export default {
        name : 'HolidayList',
        components : {HolidayDetails},
        data() {
            return {
                headers : [
                    {
                        text: 'Date',
                        align: 'start',
                        sortable: true,
                        value: 'holiday_date'
                    },
                    {
                        text: 'Type',
                        align: 'start',
                        sortable: true,
                        value: 'holiday_description'
                    },
                    {
                        text: 'Description',
                        align: 'start',
                        sortable: true,
                        value: 'holiday_description'
                    },
                    {
                        text: 'Day of Week',
                        align: 'start',
                        sortable: true,
                        value: 'day_of_week'
                    },
                    {
                        text: 'Action',
                        align: 'start',
                        sortable: true,
                        value: ''
                    }
                ],
                holidays : [],
                holiday : {},
                idx : -1,
                holiday_dialog : false
            }
        },
        mounted() {
            this.loadData();
        },
        methods: {
            loadData() {
                axios({
                    url : this.$store.state.api_url + '/holidays',
                    method : 'GET',
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.holidays = res.data.map((d, idx) => {
                        d.idx = idx;
                        return d;
                    });

                });
            },
            add() {
                this.idx = -1;
                this.holiday = {};
                this.holiday_dialog = true;
            },
            edit(holiday, idx) {
                this.idx = idx;
                this.holiday = {
                    id : holiday.id,
                    holiday_description : holiday.description,
                    holiday_type : holiday.type,
                    holiday_date : holiday.date2
                };
                this.holiday_dialog = true;
            },
            deleteRecord(holiday) {
                if(confirm('Are you sure?')) {
                    axios({
                        url : this.$store.state.api_url + '/holidays/' + holiday.id,
                        method : 'DELETE',
                        headers : {
                            Authorization : 'Bearer ' + this.$store.getters.getToken
                        }
                    }).then(res => {
                        this.loadData();
                    })
                }
            },
            update(holiday) {
                if(this.idx == -1) {
                    this.holidays.push(holiday);
                }
                else {
                    holiday.idx = this.idx;
                    this.$set(this.holidays, this.idx, holiday);
                }

                console.log(this.holidays);

                this.holiday_dialog = false;
            }
        }
    }
</script>