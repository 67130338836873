<template>
    <div class="pa-5">
        <v-card>
            <v-progress-linear
            indeterminate
            color="primary"
            v-if="$store.state.loading"
            ></v-progress-linear>
            <v-card-text>
                <v-row>
                    <v-col sm="3">
                        <v-list dense>
                            <v-subheader>COMPANIES</v-subheader>
                            <v-list-item 
                                v-for="c in categories" :key="c.id"
                                @click="viewCategoryDetails(c)" 
                                :class="category.id == c.id ? 'active-class' : ''">
                                <v-list-item-content>
                                    <v-list-item-title>{{ c.name }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col sm="9">
                        <v-card>
                            <v-progress-linear
                                indeterminate
                                color="primary"
                                v-if="loading.users"
                                >
                            </v-progress-linear>
                            <v-card-text>
                                <v-row>
                                    <v-col><strong>Users</strong></v-col>
                                    <v-col class="text-right">
                                        <v-btn @click="addUser()" color="primary" x-small>Add New User</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-data-table
                                :headers="headers_user"
                                :items="users"
                                class="elevation-1 table-sm"
                                :search="search"
                                >
                                <template v-slot:item="{item}">
                                    <tr>
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.email }}</td>
                                        <td>
                                            <v-btn x-small color="orange" class="white--text mr-2" @click="editUser(item)">edit</v-btn>
                                            <v-btn x-small color="red" class="white--text mr-2" @click="deleteUser(item.id)">delete</v-btn>
                                            <v-btn x-small color="default" @click="resetPassword(item.id)">Reset Password</v-btn>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-card>
                        <v-card class="mt-5">
                            <v-progress-linear
                                indeterminate
                                color="primary"
                                v-if="loading.companies"
                                >
                            </v-progress-linear>
                            <v-card-text>
                                <v-row>
                                    <v-col><strong>Clients</strong></v-col>
                                    <v-col class="text-right">
                                        <v-btn @click="addCompany()" color="primary" x-small>Add New Company</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-data-table
                                :headers="headers_cat"
                                :items="companies"
                                class="elevation-1 table-sm"
                                :search="search"
                                >
                                <template v-slot:item="{item}">
                                    <tr>
                                        <td :title="item.address">{{ item.company_name }}</td>
                                        <td>
                                            <v-btn x-small color="indigo" 
                                                class="white--text" 
                                                v-if="item.gdrive_folder_id"
                                                @click="viewGdriveFolder(item.gdrive_folder_id)"
                                                >GDrive Folder
                                            </v-btn>

                                            <v-btn x-small 
                                                v-if="!item.gdrive_folder_id"
                                                :disabled="$store.state.loading"
                                                @click="createGDriveFolder(item.id)"
                                                >Create GDrive Folder
                                            </v-btn>
                                        </td>
                                        <td>
                                            <v-btn x-small color="orange" class="white--text mr-2" @click="editCompany(item)">edit</v-btn>
                                            <v-btn x-small color="red" class="white--text" @click="deleteCompany(item.id)">delete</v-btn>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-card>
                        <v-card class="mt-5">
                            <v-progress-linear
                                indeterminate
                                color="primary"
                                v-if="loading.job_types"
                                >
                            </v-progress-linear>
                            <v-card-text>
                                <v-row>
                                    <v-col><strong>Job Types</strong></v-col>
                                    <v-col class="text-right">
                                        <v-btn @click="addJobType()" color="primary" x-small>Add Job Type</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-data-table
                                :headers="headers_jt"
                                :items="job_types"
                                class="elevation-1 table-sm"
                                :search="search"
                                >
                                <template v-slot:item="{item}">
                                    <tr>
                                        <td>{{ item.job_type }}</td>
                                        <td>{{ item.description }}</td>
                                        <td>
                                            <v-btn x-small color="orange" class="white--text mr-2" @click="editJobType(item)">edit</v-btn>
                                            <v-btn x-small color="red" class="white--text" @click="deleteJobType(item.id)">delete</v-btn>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog width="400" v-model="show_company_details">
            <v-card>
                <v-card-title>Client Details</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <div class="mt-5">
                        <v-select dense outlined label="Company" 
                            v-model="company.category_id" 
                            :items="categories"
                            item-value="id"
                            item-text="name"></v-select>
                        <v-text-field dense outlined label="Client Name" v-model="company.company_name"></v-text-field>
                        <v-text-field dense outlined label="Address" v-model="company.address"></v-text-field>
                        <v-textarea dense outlined label="Description" v-model="company.description"></v-textarea>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" block @click="saveCompany(company)">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog width="400" v-model="show_user_details">
            <v-card>
                <v-card-title>User Details</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <div class="mt-5">
                        <v-select dense outlined label="Company" 
                            v-model="user.company_category_id" 
                            :items="categories"
                            item-value="id"
                            item-text="name"></v-select>
                        <v-text-field outlined dense label="Name" v-model="user.name"></v-text-field>
                        <v-text-field outlined dense label="Email" v-model="user.email"></v-text-field>
                        <v-select outlined dense label="Role" 
                            :items="user_roles" 
                            item-value="id" 
                            item-text="text"
                            v-model="user.account_type"></v-select>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" block @click="saveUser(user)">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog width="400" v-model="show_job_type_details">
            <v-card>
                <v-card-title>Job Type Details</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <div class="mt-5">
                        <v-select dense outlined label="Company" 
                            v-model="job_type.category_id" 
                            :items="categories"
                            item-value="id"
                            item-text="name"></v-select>
                        <v-text-field dense outlined label="Job Type" v-model="job_type.job_type"></v-text-field>
                        <v-textarea dense outlined label="Description" v-model="job_type.description"></v-textarea>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" block @click="saveJobType(job_type)">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import axios from 'axios';
    import CompanyDetails from '@/components/company/CompanyDetails.vue';
    import CompanyUsers from '@/components/company/CompanyUsers.vue';
    import JobTypes from '@/components/company/JobTypes.vue';
    export default {
        name : 'CompaniesView',
        components : {CompanyUsers, JobTypes, CompanyDetails},
        data() {
            return {
                show_users : false,
                show_job_types : false,
                show_company_details : false,
                show_user_details : false,
                show_job_type_details : false,
                headers_cat : [
                    {
                        text : 'Company Name',
                        align : 'start',
                        sortable : true,
                        value : 'company_name'
                    },{
                        text : 'G Drive',
                        align : 'start',
                        sortable : false,
                    },
                    {
                        text : 'Actions',
                        align : 'start',
                        sortable : false,
                    }
                ],
                headers_user : [
                    {
                        text : 'Name',
                        align : 'start',
                        sortable : false,
                    },{
                        text : 'Email',
                        align : 'start',
                        sortable : false,
                    },
                    {
                        text : 'Actions',
                        align : 'start',
                        sortable : false,
                    }
                ],
                search : '',
                users : [],
                user : {},
                user_roles : [
                    {id : 'client-manager', text : 'Manager'},
                    {id : 'client-user', text : 'User'},
                ],
                companies : [],
                company : {},
                categories : [],
                category : {},
                job_types : [],
                job_type : {},
                loading : {
                    users : false,
                    job_types : false,
                    companies : false
                },
                headers_jt : [
                    {
                        text : 'Name',
                        align : 'start',
                        sortable : true,
                        value : 'job_type'
                    },
                    {
                        text : 'Description',
                        align : 'start',
                        sortable : true,
                        value : 'description'
                    },
                    {
                        text : 'Action',
                        align : 'start',
                        sortable : false,
                    }
                ]
            }
        },
        mounted() {
            this.getCategories(true);
        },
        methods : {
            getCategories(reload = false) {
                this.$store.state.loading = true;
                axios({
                    url : this.$store.state.api_url + '/categories',
                    method : 'GET',
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    let output = res.data;
                    this.categories = output.data;
                    if(reload) {
                        if(this.categories.length > 0) {
                            this.viewCategoryDetails(this.categories[0]);
                        }
                    }
                    this.$store.state.loading = false;
                }).catch(err => {
                    this.$store.state.loading = false;
                    alert(JSON.stringify(err));
                });
            },
            viewCategoryDetails(category) {
                this.category = category;
                
                this.viewCompanies(category.id);
                this.viewUsers(category.id);
                this.viewJobTypes(category.id);
            },
            viewCompanies(category_id) {
                this.loading.companies = true;
                axios({
                    url : this.$store.state.api_url + '/categories/' + category_id + '/companies',
                    method : 'GET',
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    let output = res.data;
                    this.companies = output.data;
                    this.loading.companies = false;
                }).catch(err => {
                    this.loading.companies = false;
                });
            },
            viewUsers(category_id){
                this.loading.users = true;
                axios({
                    url : this.$store.state.api_url + '/categories/' + category_id + '/users',
                    method : 'GET',
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    let output = res.data;
                    this.users = output.data;
                    this.loading.users = false;
                }).catch(err => {
                    alert(JSON.stringify(err));
                    this.loading.users = false;
                });
            },
            addCompany() {
                this.company = {};
                this.company.category_id = this.category.id;
                this.show_company_details = true;
            },
            editCompany(company) {
                this.company = Object.assign({}, company);
                this.show_company_details = true;
            },
            viewJobTypes(id) {
                this.loading.job_types = true;
                axios({
                    url : this.$store.state.api_url + '/categories/'+ id  +'/job-types',
                    method : 'GET',
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    let output = res.data;
                    this.job_types = output.data;
                    this.loading.job_types = false;
                }).catch(err => {
                    alert(JSON.stringify(err));
                    this.loading.job_types = false;
                });

                this.show_job_types = true;
            },
            addJobType() {
                this.job_type = {};
                this.show_job_type_details = true;
            },
            editJobType(job_type) {
                this.job_type = Object.assign({}, job_type);
                this.show_job_type_details = true;
            },
            saveJobType(job_type) {
                this.$store.state.loadin = true;
                if(job_type.id != undefined) {
                    axios({
                        url : this.$store.state.api_url + '/categories/'+ this.category.id  +'/job-types/' + job_type.id,
                        method : 'PUT',
                        data : job_type,
                        headers : {
                            Authorization : 'Bearer ' + this.$store.getters.getToken
                        }
                    }).then(res => {
                        let output = res.data;
                        this.viewJobTypes(this.category.id);
                        this.show_job_type_details = false;
                    });
                }
                else {
                    axios({
                        url : this.$store.state.api_url + '/categories/'+ this.category.id  +'/job-types',
                        method : 'POST',
                        data : job_type,
                        headers : {
                            Authorization : 'Bearer ' + this.$store.getters.getToken
                        }
                    }).then(res => {
                        let output = res.data;
                        this.viewJobTypes(this.category.id);
                        this.show_job_type_details = false;
                    });
                }
            },
            saveCompany(company) {
                this.$store.state.loading = true;
                if(company.id != undefined) {
                    axios({
                        url : this.$store.state.api_url + '/categories/'+ this.category.id  +'/companies/' + this.company.id,
                        method : 'PUT',
                        data : company,
                        headers : {
                            Authorization : 'Bearer ' + this.$store.getters.getToken
                        }
                    }).then(res => {
                        this.viewCompanies(this.category.id);
                        this.show_company_details = false;
                        this.$store.state.loading = false;
                    }).then(err => {
                        alert(err.response.data.errors.join("\n"));
                        this.$store.state.loading = false;
                    });
                }
                else {
                    axios({
                        url : this.$store.state.api_url + '/categories/'+ this.category.id  +'/companies',
                        method : 'POST',
                        data : company,
                        headers : {
                            Authorization : 'Bearer ' + this.$store.getters.getToken
                        }
                    }).then(res => {
                        this.viewCompanies(this.category.id);
                        this.show_company_details = false;
                        this.$store.state.loading = false;
                    }).then(err => {
                        alert(err.response.data.errors.join("\n"));
                        this.$store.state.loading = false;
                    });
                }
            },
            deleteCompany(id) {
                if(confirm('Are you sure?')) {
                    axios({
                        url : this.$store.state.api_url + '/categories/'+ this.category.id  +'/companies/' + id,
                        method : 'DELETE',
                        headers : {
                            Authorization : 'Bearer ' + this.$store.getters.getToken
                        }
                    }).then(res => {
                        this.viewCompanies(this.category.id);
                    }).then(err => {
                        alert(err.response.data.errors.join("\n"));
                        this.$store.state.loading = false;
                    });
                }
            },
            addUser() {
                this.user = {};
                this.user.company_category_id = this.category.id;
                this.show_user_details = true;
            },
            editUser(user) {
                console.log(user);
                this.user = {...user};
                this.show_user_details = true;
            },
            resetPassword() {

            },
            saveUser(user) {
                this.$store.state.loading = true;
                if(user.id != undefined) {
                    axios({
                        url : this.$store.state.api_url + '/categories/'+ this.category.id  +'/users/' + this.user.id,
                        method : 'PUT',
                        data : user,
                        headers : {
                            Authorization : 'Bearer ' + this.$store.getters.getToken
                        }
                    }).then(res => {
                        this.viewUsers(this.category.id);
                        this.show_user_details = false;
                        this.$store.state.loading = false;
                    }).catch(err => {
                        alert(err.response.data.errors.join("\n"));
                        this.$store.state.loading = false;
                    });
                }
                else {
                    axios({
                        url : this.$store.state.api_url + '/categories/'+ this.category.id  +'/users',
                        method : 'POST',
                        data : user,
                        headers : {
                            Authorization : 'Bearer ' + this.$store.getters.getToken
                        }
                    }).then(res => {
                        this.viewUsers(this.category.id);
                        this.show_user_details = false;
                        this.$store.state.loading = false;
                    }).catch(err => {
                        alert(err.response.data.errors.join("\n"));
                        this.$store.state.loading = false;
                    });
                }
            },
            deleteUser(id) {
                if(confirm('Are you sure?')) {
                    axios({
                        url : this.$store.state.api_url + '/categories/'+ this.category.id  +'/users/' + id,
                        method : 'DELETE',
                        headers : {
                            Authorization : 'Bearer ' + this.$store.getters.getToken
                        }
                    }).then(res => {
                        this.viewUsers(this.category.id);
                        this.$store.state.loading = false;
                    }).then(err => {
                        alert(err.response.data.errors.join("\n"));
                        this.$store.state.loading = false;
                    });
                }
            },
            refreshJobTypes(types) {
                this.company.job_types = types;
            },
            viewGdriveFolder(folder_id) {
                var gdrive = 'https://drive.google.com/drive/u/0/folders/' + folder_id;
                window.open(gdrive, '_blank');
            },
            createGDriveFolder(company_id) {
                this.$store.state.loading = true;
                axios({
                    url : this.$store.state.api_url + '/companies/' + company_id + '/gdrive',
                    method : 'POST',
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.refreshCompanies();
                    this.$store.state.loading = false;
                }).catch(err => {
                    alert(JSON.stringify(err.response));
                    this.$store.state.loading = false;
                });
            }
        }
    }
</script>
<style scoped>
.active-class {
  background-color: #e0e0e0;
}
</style>