<template>
    <div>
        <v-row no-gutters>
            <v-col cols="12" sm="8" md="9" class="login-bg">

            </v-col>
            <v-col cols="12" sm="4" md="3">
                <div class="pa-5">
                    <div class="text-center">
                        <img src="@/assets/logo.png" alt="" height="80">
                        <div class="text-center mt-3">
                            <h3>LUNTIAN</h3>
                            <div>DESIGN & SOLUTIONS</div>
                        </div>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="pa-5">
                    <div class="text-center mt-2 mb-3">
                        <h3>Log-In</h3>
                    </div>
                    <v-alert dense outlined type="error" v-if="login_error.failed" @click="login_error.failed = false">
                        {{ login_error.message }}
                    </v-alert>
                    <v-text-field color="indigo" outlined dense label="Email" type="email" v-model="user.email"></v-text-field>
                    <v-text-field color="indigo" outlined dense label="Password" type="password" v-model="user.password"></v-text-field>
                    <v-btn block color="indigo" class="darken-3 white--text" 
                        @click="login()"
                        :disabled="$store.state.loading">Log-In</v-btn>

                </div>
            </v-col>
        </v-row>
    </div>
</template>
<style scoped>
    .login-bg {
        background : url('@/assets/login-bg.jpg') no-repeat;
        background-size: cover;
        height: 100vh;
    }
</style>
<script>
    import axios from 'axios';
    export default {
        name : 'LoginView',
        data() {
            return {
                user : {
                    auth_type : 1,
                },
                login_error : {
                    failed : false,
                    message : 'null'
                }
            }
        },
        mounted() {
            // console.log(this.$store.state.token);
            // console.log(this.$store.getters.getToken);
            // console.log(this.$store.dispatch('setToken', 'Test dispatch')); //Calling Action
            // console.log(this.$store.getters.getToken);

        },
        methods : {
            login() {
                this.$store.dispatch('loading', true);
                axios({
                    url : process.env.VUE_APP_API_URL + '/login',
                    method : 'POST',
                    data : this.user
                }).then(output => {
                    this.$store.dispatch('login', output.data.data);
                    this.$store.dispatch('loading', false);
                    this.$router.push({name : 'Jobs', params: {type : 1}});

                }).catch(err =>  {
                    this.$store.dispatch('loading', false);

                    this.login_error = {
                        failed : true,
                        message : err.response.data.message
                    };

                    console.log(err.response.data.message);
                });
            }
        }

    }
</script>