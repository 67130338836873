import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-5"},[_c(VCard,{attrs:{"width":"800"}},[_c(VCardTitle,[_vm._v("My Profile")]),_c(VDivider),_c(VCardText,[_c(VTextField,{attrs:{"dense":"","label":"Name","outlined":""},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c(VTextField,{attrs:{"dense":"","label":"Email","outlined":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c(VTextField,{attrs:{"dense":"","label":"Accout Type","outlined":""},model:{value:(_vm.user.account_type),callback:function ($$v) {_vm.$set(_vm.user, "account_type", $$v)},expression:"user.account_type"}}),_c(VCheckbox,{attrs:{"label":"Change Password"},model:{value:(_vm.change_password),callback:function ($$v) {_vm.change_password=$$v},expression:"change_password"}}),(_vm.change_password)?_c('div',[_c(VTextField,{attrs:{"dense":"","label":"Current Password","outlined":"","type":"password"},model:{value:(_vm.password.password),callback:function ($$v) {_vm.$set(_vm.password, "password", $$v)},expression:"password.password"}}),_c(VTextField,{attrs:{"dense":"","label":"New Password","outlined":"","type":"password"},model:{value:(_vm.password.new_password),callback:function ($$v) {_vm.$set(_vm.password, "new_password", $$v)},expression:"password.new_password"}}),_c(VTextField,{attrs:{"dense":"","label":"Confirm New Password","outlined":"","type":"password"},model:{value:(_vm.password.new_password_confirmation),callback:function ($$v) {_vm.$set(_vm.password, "new_password_confirmation", $$v)},expression:"password.new_password_confirmation"}}),_c('div',{staticClass:"text-right"},[_c(VBtn,{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.changePassword()}}},[_vm._v("Update Password")])],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }