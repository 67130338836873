<template>
    <div>
        <v-row class="secondary" fixed>
            <v-col :style="{'display': 'flex', 'align-items' : 'center'}">
                <div class="pa-2">
                    <h4 class="text-uppercase"> 
                        Current Status : {{ job.job_status }}
                        <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                color="primary"
                                x-small
                                icon
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon>mdi-account</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ job.validator.name }} ({{ job.validator.email }})</span>
                        </v-tooltip>
                    </h4>
                </div>
            </v-col>
            <v-col class="text-right">
                <div class="pa-2">
                    <v-btn color="orange mr-3" class="darken-3 white--text" small
                        @click="startChecking()"
                        :disabled="$store.state.loading"
                        v-if="job.job_status == 'submitted for checking'"
                        >
                        <v-icon>{{ $store.state.loading ? 'mdi-loading mdi-spin' : 'mdi-play' }}</v-icon> Start Checking
                    </v-btn>

                    <v-btn color="red mr-3" class="darken-3 white--text" small
                        @click="markForRevision()"
                        :disabled="$store.state.loading"
                        v-if="job.job_status == 'checking started'"
                        >
                        <v-icon>{{ $store.state.loading ? 'mdi-loading mdi-spin' : 'mdi-refresh' }}</v-icon> Mark for Revision
                    </v-btn>

                    <v-btn color="primary mr-3" class="white--text" small
                        @click="finishChecking()"
                        :disabled="$store.state.loading"
                        v-if="job.job_status == 'checking started'"
                        >
                        <v-icon>{{ $store.state.loading ? 'mdi-loading mdi-spin' : 'mdi-check' }}</v-icon> Save and Submit
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-tabs v-model="tab" color="primary">
            <v-tab key="tab1">
                Job Details
            </v-tab>
            <v-tab key="tab2">
                Job Submission Section
            </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
            <v-tab-item key="tab1" class="pa-3">
                <v-card>
                    <v-card-text class="grey lighten-3 pa-2">
                        <v-row no-gutters>
                            <v-col :style="{'display': 'flex', 'align-items' : 'center'}">
                                <strong>CUSTOMER DETAILS</strong>
                            </v-col>
                        </v-row>
                        
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-text-field label="Luntian Ref #" outlined dense v-model="job.lbs_ref_no" readonly hide-details></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field label="Client Ref #" outlined dense v-model="job.client_ref_no" readonly hide-details></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field label="Version" outlined dense v-model="job.version" readonly hide-details></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field label="Submitted By" readonly outlined dense v-model="job.client.name" hide-details></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field label="Company" readonly outlined dense v-model="job.company" hide-details></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field label="Submission Date" outlined dense v-model="job.date_submitted" readonly hide-details></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class="mt-5">
                    <v-card-text class="grey lighten-3 pa-2">
                        <strong>JOB DETAILS</strong>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-text-field 
                                    v-model="job.job_address" 
                                    outlined label="Job Address" 
                                    dense 
                                    readonly
                                    hide-details>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="8" sm="12">
                                <v-text-field 
                                label="Job Type" 
                                readonly 
                                outlined 
                                dense 
                                :value="job.job_type"
                                hide-details>
                            </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" sm="12">
                                <v-text-field 
                                    label="Priority" 
                                    readonly 
                                    outlined 
                                    dense 
                                    v-model="job.priority"
                                    hide-details>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea label="Notes" dense outlined v-model="job.notes" readonly hide-details></v-textarea>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-row class="mt-2">
                    <v-col>
                        <v-card>
                            <v-card-text class="grey lighten-3 pa-2">
                                <strong>PLANS</strong>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-simple-table>
                                <template v-slot:default>
                                    <tbody>
                                        <tr v-for="f, idx in job.plans" :key="f.id">
                                            <td class="text-blue cursor" @click="openLink('plans', f.job_id, f.type, f.filename)">{{ f.filename }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card>
                            <v-card-text class="grey lighten-3 pa-2">
                                <strong>DOCUMENTS</strong>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-simple-table>
                                <template v-slot:default>
                                    <tbody>
                                        <tr v-for="f, idx in job.documents" :key="f.id">
                                            <td class="text-blue cursor" @click="openLink('documents', f.job_id, f.type, f.filename)">
                                                {{ f.filename }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item key="tab2" class="pa-3">
                <v-row>
                    <v-col>
                        <v-card :disabled="job.job_status != 'checking started'">
                            <v-card-text class="grey lighten-3 pa-2">
                                <strong>DETAILS</strong>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <v-text-field 
                                            label="Region - CZ"
                                            outlined 
                                            dense 
                                            v-model="job.region_sz"
                                            readonly
                                            hide-details>
                                        </v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field 
                                            label="Floor Basis"
                                            outlined 
                                            dense 
                                            v-model="job.floor_basis"
                                            readonly
                                            hide-details>
                                        </v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field 
                                            label="Base Heating Load (Mj/M2)" 
                                            outlined 
                                            readonly
                                            dense 
                                            v-model="job.base_heating_load"
                                            hide-details>
                                        </v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field 
                                            label="Base Cooling Load (Mj/M2)" 
                                            outlined 
                                            readonly
                                            dense 
                                            v-model="job.base_cooling_load"
                                            hide-details>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-textarea 
                                            hide-details
                                            label="Run Notes"
                                            v-model="job.run_notes"
                                            readonly
                                            outlined
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <div>
                                            <label for="">Plan Complexity</label>
                                        </div>
                                        <v-rating
                                            v-model="job.plan_complexity"
                                            color="yellow darken-3"
                                            background-color="grey darken-1"
                                            hover
                                            large
                                            readonly
                                            >
                                        </v-rating>
                                        <div>
                                            <small>1 star = Hard to model 5 star = Easy to model</small>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card :disabled="job.job_status != 'checking started'">
                            <v-card-text class="grey lighten-3 pa-2">
                                <strong>FR5</strong>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-simple-table>
                                <template v-slot:default>
                                    <tbody>
                                        <tr v-for="f, idx in job.frates" :key="f.id">
                                            <td class="text-blue cursor"
                                                @click="openLink('frates', f.job_id, f.type, f.filename)">
                                                {{ f.filename }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card :disabled="job.job_status != 'checking started'">
                            <v-card-text class="grey lighten-3 pa-2">
                                <strong>DB FILES</strong>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-simple-table>
                                <template v-slot:default>
                                    <tbody>
                                        <tr v-for="f, idx in job.dbs" :key="f.id">
                                            <td class="text-blue cursor"
                                                @click="openLink('dbs', f.job_id, f.type, f.filename)">
                                                {{ f.filename }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card :disabled="job.job_status != 'checking started'">
                            <v-card-text class="primary white--text pa-2">
                                <strong>CHECKER REMARKS</strong>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text>
                                <editor :content.sync="job.checker_remarks" @update="updateRemarks"></editor>
                                <!-- <v-textarea label="Add Remarks" outlined dense hide-details v-model="job.checker_remarks"></v-textarea> -->
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog width="500" v-model="view_revise_options">
            <v-card dark>
                <v-card-title>
                    Job Revision
                </v-card-title>
                <v-card-text>
                    Do you want to revise this job?
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn small color="orange" class="darken-2" @click="edit_mode = true; view_revise_options = false;">Yes, I will do the revision</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn small color="red" class="darken-4" @click="submitRevisionMark()">No, I want to send it back</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
    .text-blue {
        color : blue;
    }
    .cursor {
        cursor: pointer;
    }
</style>
<script>
import axios from 'axios';
import { globalMixins } from '@/mixins/globalMixins';
import CheckerList from '@/components/CheckerList.vue';
import Editor from '@/components/Editor.vue';
export default 
{
    name : 'JobDetails',
    mixins : [globalMixins],
    components : {
        CheckerList,
        Editor
    },
    data() {
        return {
            tab : 'tab1',
            new_frates : [],
            new_dbs : [],
            job : {
                client : {}
            },
            show_checkers : false,
            staffs : [],
            view_revise_options : false,
            edit_mode : false
        }
    },
    mounted() {
        axios({
            url : process.env.VUE_APP_API_URL + '/jobs/' + this.$route.params.id,
            method : 'GET',
            headers : {
                Authorization : 'Bearer ' + this.$store.getters.getToken
            }
        }).then(response => {
            this.job = response.data;
        });
    },
    methods : {
        startChecking() {
            this.$store.dispatch('loading', true);
            axios({
                url : this.$store.state.api_url + '/jobs/'+ this.job.id +'/start-checking',
                method : 'PUT',
                headers : {
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(response => {
                this.job = response.data;

                this.$store.dispatch('loading', false);
            }).catch(err => {
                this.$store.dispatch('loading', false);
            });
        },
        updateRemarks(remarks) {
            this.job.checker_remarks = remarks;
        },
        finishChecking() {
            this.$store.dispatch('loading', true);
            axios({
                url : this.$store.state.api_url + '/jobs/'+ this.job.id +'/complete',
                method : 'PUT',
                data : this.job,
                headers : {
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(response => {
                this.job = response.data;
                this.$router.push({name : 'Jobs'});
                this.$store.dispatch('loading', false);
            }).catch(err => {
                this.$store.dispatch('loading', false);
            });
        },
        markForRevision() {
            this.view_revise_options = true;
        },
        submitRevisionMark() {
            this.$store.dispatch('loading', true);
            axios({
                url : this.$store.state.api_url + '/jobs/'+ this.job.id +'/revision',
                method : 'PUT',
                params : {action : 'marked for revision'},
                data : this.job,
                headers : {
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(response => {
                this.job = response.data;
                this.$router.push({name : 'Jobs'});
                this.$store.dispatch('loading', false);
            }).catch(err => {
                this.$store.dispatch('loading', false);
            });
        },
        viewFrateFile(filename) {
            window.open(this.$store.state.host_url + '/uploads/frates/' + this.job.id + '/' + filename);
        },
        viewDbFile(filename) {
            window.open(this.$store.state.host_url + '/uploads/dbs/' + this.job.id + '/' + filename);
        },
    }
}
</script>