<template>
    <div class="pa-5">
        <div class="mb-3 text-right">
            <v-btn color="primary" @click="addUser()" small>Add User</v-btn>
        </div>
        <v-data-table
            :headers="headers"
            :items="users"
            class="elevation-1"
        >
            <template v-slot:item="{item}">
            <tr>
                <td>{{ item.name }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.account_type }}</td>
                <td>{{ item.assigned_jobs.length }}</td>
                <td>{{ item.completed_jobs.length }}</td>
            </tr>
            </template>
        </v-data-table>
        <v-dialog width="400" v-model="user_details">
            <user-details 
                :user="user" 
                :type="action_type" 
                @close="user_details = false;"
                @appendUser="append"
                @updateUser="update">
            </user-details>
        </v-dialog>
    </div>
</template>
<script>
import axios from 'axios';
import UserDetails from '@/components/admin/UserDetails.vue';
export default {
    name : 'UserList',
    components : {
        UserDetails
    },
    data() {
        return {
            action_type : null,
            user : {},
            users : [],
            user_details : false,
            headers : [
                {
                    text: 'Name',
                    align: 'start',
                    sortable: true,
                    value: 'name'
                },
                {
                    text: 'Email',
                    align: 'start',
                    sortable: true,
                    value: 'email'
                },
                {
                    text: 'Role',
                    align: 'start',
                    sortable: true,
                    value: 'email'
                },
                {
                    text: 'Active Jobs',
                    align: 'start',
                    sortable: true,
                    value: 'active_jobs'
                },
                {
                    text: 'Completed Jobs',
                    align: 'start',
                    sortable: true,
                    value: 'completed_jobs'
                },
            ]
        }
    },
    mounted() {
        axios({
            url : this.$store.state.api_url + '/users',
            method : 'GET',
            headers : {
                Authorization : 'Bearer ' + this.$store.getters.getToken
            }
        }).then(res => {
            this.users = res.data;
        }).catch(err => {
            alert(JSON.stringify(err));
        })
    },
    methods: {
        addUser() {
            this.user = {};
            this.action_type = 'add';
            this.user_details = true;
        },
        append(user) {
            this.users.push(user);
            this.user_details = false;
        } 
    },
}
</script>