import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,[_vm._v("Holiday Details")]),_c(VCardText,[_c(VTextField,{attrs:{"label":"Description","outlined":"","dense":""},model:{value:(_vm.holiday.holiday_description),callback:function ($$v) {_vm.$set(_vm.holiday, "holiday_description", $$v)},expression:"holiday.holiday_description"}}),_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Holiday Date","readonly":"","outlined":"","dense":""},model:{value:(_vm.holiday.holiday_date),callback:function ($$v) {_vm.$set(_vm.holiday, "holiday_date", $$v)},expression:"holiday.holiday_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datepicker1),callback:function ($$v) {_vm.datepicker1=$$v},expression:"datepicker1"}},[_c(VDatePicker,{on:{"input":function($event){_vm.datepicker1 = false}},model:{value:(_vm.holiday.holiday_date),callback:function ($$v) {_vm.$set(_vm.holiday, "holiday_date", $$v)},expression:"holiday.holiday_date"}})],1),_c(VSelect,{attrs:{"items":_vm.types,"label":"Holiday Type","dense":"","outlined":""},model:{value:(_vm.holiday.holiday_type),callback:function ($$v) {_vm.$set(_vm.holiday, "holiday_type", $$v)},expression:"holiday.holiday_type"}})],1),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"block":"","color":"primary","small":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Save ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }