import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-5"},[_c(VRow,[_c(VCol,{staticClass:"text-right mb-3"},[_c(VBtn,{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.add()}}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" New Holiday Record ")],1)],1)],1),_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.holidays},scopedSlots:_vm._u([{key:"item",fn:function({item}){return [_c('tr',[_c('td',[_vm._v(_vm._s(item.date))]),_c('td',[_vm._v(_vm._s(item.type))]),_c('td',[_vm._v(_vm._s(item.description))]),_c('td',[_vm._v(_vm._s(item.day_of_week))]),_c('td',[_c(VBtn,{attrs:{"x-small":"","icon":"","color":"green"},on:{"click":function($event){return _vm.edit(item, item.idx)}}},[_c(VIcon,[_vm._v("mdi-square-edit-outline")])],1),_vm._v("   "),_c(VBtn,{attrs:{"x-small":"","icon":"","color":"red"},on:{"click":function($event){return _vm.deleteRecord(item)}}},[_c(VIcon,[_vm._v("mdi-delete-outline")])],1)],1)])]}}])}),_c(VDialog,{attrs:{"width":"400"},model:{value:(_vm.holiday_dialog),callback:function ($$v) {_vm.holiday_dialog=$$v},expression:"holiday_dialog"}},[_c('holiday-details',{attrs:{"holiday":_vm.holiday},on:{"update":_vm.update}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }