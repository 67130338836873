<template>
    <v-card>
        <v-card-text class="grey lighten-2 text-uppercase pa-3">
            <strong>{{ company.company_name }} JOB TYPES</strong>
        </v-card-text>
        <v-divider></v-divider>
        <v-progress-linear
            v-if="$store.state.loading"
            indeterminate
            color="primary">
        </v-progress-linear>
        <v-card-text class="pa-3">
            <v-row>
                <v-col cols="12" md="10" sm="8">
                    <v-text-field label="Enter Job Type" hide-details dense outlined v-model="job_type"></v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="4">
                    <v-btn @click="add()">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-simple-table>
            <template v-slot:default>
                <thead class="grey lighten-2 white--text">
                    <tr>
                        <th class="text-left">
                            Job Type
                        </th>
                        <th style="width: 100px;">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="(item, idx) in company.job_types"
                    :key="item.id"
                    >
                        <td>
                            <span v-if="idx != edit_idx">{{ item.job_type }}</span>
                            <v-text-field class="tf-small" dense hide-details outlined v-if="idx == edit_idx" v-model="edit_job_type.job_type"></v-text-field>
                        </td>
                        <td>
                            <div v-if="idx != edit_idx">
                                <v-btn x-small icon color="success"
                                    @click="edit(idx, item)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                                <v-btn x-small icon color="error"
                                    @click="remove(item.id)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </div>
                            <div v-if="idx == edit_idx">
                                <v-btn x-small icon color="primary" 
                                    @click="update()">
                                    <v-icon>mdi-floppy</v-icon>
                                </v-btn>

                                <v-btn x-small icon color="error" 
                                    @click="undo()">
                                    <v-icon>mdi-undo</v-icon>
                                </v-btn>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
</template>
<style scoped>
    .text-uppercase {
        text-transform: uppercase;
    }
    .tf-small {
        font-size: 14px;
        padding : 2px;
    }
</style>
<script>
import axios from 'axios';
export default {
    name : 'JobTypes',
    props : ['company'],
    data() {
        return {
            job_type : '',
            edit_idx : -1,
            edit_job_type : {}
        }
    },
    methods : {
        add() {
            this.$store.state.loading = true;
            axios({
                url : this.$store.state.api_url + '/companies/' + this.company.id + '/job-types',
                method : 'POST',
                data : {job_type : this.job_type},
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                this.$store.state.loading = false;
                this.company.job_types.push(res.data.data);
                this.job_type = '';
                this.$emit('refresh', this.company.job_types);
            }).catch(err => {
                this.$store.state.loading = false;
            });
        },
        edit(idx, job_type) {
            this.edit_idx = idx;
            this.edit_job_type = {...job_type};
        },
        update() {
            this.$store.state.loading = true;
            axios({
                url : this.$store.state.api_url + '/companies/' + this.company.id + '/job-types/' + this.edit_job_type.id,
                method : 'PUT',
                data : this.edit_job_type,
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                this.edit_idx = -1,
                this.edit_job_type = {}
                this.$store.state.loading = false;
                this.$emit('refresh', res.data.data);
            }).catch(err => {
                this.$store.state.loading = false;
            })
        },
        remove(id) {
            if(confirm('Are you sure?')) {
                this.$store.state.loading = true;
                axios({
                    url : this.$store.state.api_url + '/companies/' + this.company.id + '/job-types/' + id,
                    method : 'DELETE',
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.$store.state.loading = false;
                    this.$emit('refresh', res.data.data);
                }).catch(err => {
                    console.log(err.response.data.statusText);
                    this.$store.state.loading = false;
                })
            }
        },
        undo() {
            this.edit_idx = -1;
            this.edit_job_type = {};
        }
    }
}
</script>