<template>
    <div class="pa-5">
        <v-card>
            <v-card-title>
                <v-btn small text @click="redirect('Jobs')"><v-icon>mdi-arrow-left</v-icon></v-btn>&nbsp;
                Customer Details
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-text-field label="LBS Ref #" outlined dense v-model="job.lbs_ref_no"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field label="Client Ref #" outlined dense v-model="job.client_ref_no"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field label="Version" outlined dense v-model="job.version"></v-text-field>
                    </v-col>
                </v-row>
                <v-text-field label="Client" readonly outlined dense v-model="$store.getters.getUser.company"></v-text-field>
            </v-card-text>
        </v-card>
        <v-card class="mt-5">
            <v-card-title>
                Job Details
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-text-field v-model="job.job_address" outlined label="Job Address" dense></v-text-field>
                <v-row>
                    <v-col cols="12" md="8" sm="12">
                        <v-select
                            :items="job_types"
                            item-text="type"
                            item-value="id"
                            label="Job Type"
                            outlined
                            dense
                            v-model="job.job_type_id"
                            >
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-select
                            :items="priorities"
                            label="Priority"
                            outlined
                            dense
                            v-model="job.priority"
                            >
                        </v-select>
                    </v-col>
                </v-row>
                <v-textarea label="Notes" dense outlined v-model="job.notes"></v-textarea>
                <v-row>
                    <v-col>
                        <v-file-input
                        multiple
                        v-model="job.plans"
                        chips
                        label="Upload Plans *"
                        outlined
                        dense
                        ></v-file-input>
                    </v-col>
                    <v-col>
                        <v-file-input
                        v-model="job.documents"
                        chips
                        multiple
                        label="Upload Documents"
                        outlined
                        dense
                        ></v-file-input>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="indigo" 
                    class="darken-4 white--text" 
                    @click="submit()"
                    :disabled="$store.state.loading">
                    <v-icon>{{ $store.state.loading ? 'mdi-refresh mdi-spin' : 'mdi-check'}}</v-icon>
                    Submit
                </v-btn>
            </v-card-actions>
        </v-card>
        <Dialog v-bind:show.sync="show_dialog" 
            width="400" 
            color="red"
            :props="dialog_props">
        </Dialog>
    </div>
</template>
<script>
import {globalMixins} from '@/mixins/globalMixins.js';
import axios from 'axios';
import Dialog from '@/components/Dialog.vue';
export default {
    name : 'NewJob',
    mixins : [globalMixins],
    components : {
        Dialog
    },
    data() {
        return {
            job : {},
            show_dialog : false,
            dialog_props : {
                width : 300,
                color : 'red',
                title : '',
                text : '',
                dark : true
            }
        }
    },
    mounted() {

    },
    methods: {
        submit() {
            this.$store.dispatch('loading',true);
            let data = new FormData();
            
            Object.keys(this.job).forEach(key => {
                if(key == 'plans') {
                    this.job[key].forEach((plan,idx) => {
                        data.append('plans['+ idx +']', plan);
                    });
                }
                else if(key == 'documents') {
                    this.job[key].forEach((plan,idx) => {
                        data.append('documents['+ idx +']', plan);
                    });
                }
                else {
                    data.append(key, this.job[key]);
                }
            });
            
            axios({
                url : process.env.VUE_APP_API_URL + '/jobs',
                method : 'POST',
                data : data,
                headers : {
                    'Content-Type' : 'multipart/form-data',
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(response => {
                this.redirect('Jobs');
                this.$store.dispatch('loading', false);
            }).catch(err => {
                if(err.response.status == 400) {
                    this.dialog_props.title = err.response.statusText;
                    this.dialog_props.text = err.response.data.errors.join("\n");
                }

                this.$store.dispatch('loading', false);
            });
        }
    }
}
</script>