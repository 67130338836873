<template>
    <div class="pa-5">
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col cols="12" sm="7">
                        Employee Leaves
                    </v-col>
                    <v-col cols="12" sm="5">
                        <v-row>
                            <v-col>
                                <v-select dense 
                                    label="Month"
                                    v-model="month" 
                                    :items="months" 
                                    item-value="value" 
                                    item-text="text">
                                </v-select>
                            </v-col>
                            <v-col>
                                <v-select dense 
                                    label="Year"
                                    v-model="year" 
                                    :items="years" 
                                    item-value="value" 
                                    item-text="text">
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-simple-table dense class="bordered-table">
                <template v-slot:default>
                    <thead class="grey lighten-2 white--text">
                        <tr>
                            <th class="text-left" style="min-width: 200px;">
                                Name
                            </th>
                            <th class="text-left" v-for="d in days" :key="d">
                                {{ d.day }}
                                <span style="font-size:10px;">{{ d.week_day }}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="l in staff_leaves" :key="l">
                            <td>
                                <span style="font-size: 11px;">
                                    {{ l.staff.name }}
                                </span>
                            </td>
                            <td v-for="d in l.days" :key="d" :class="d.leave ? getLeaveClass(d.leave) : ''" @click="showLeaveDetails(l.staff.name, d)">
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>

        <v-dialog width="400" v-model="show_leave_details">
            <v-card>
                <v-card-title>
                    Leave Details | {{ leave.name }} 
                    &nbsp;
                    <v-chip class="orange lighten-2" small v-if="leave.details.approved == 0 && leave.details.declined == 0">Pending</v-chip>
                    <v-chip class="green lighten-2" small v-if="leave.details.approved == 1">Approved</v-chip>
                    <v-chip class="red lighten-2" small  v-if="leave.details.declined == 1">Declined</v-chip>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-5">
                    <v-text-field dense label="Date" 
                        disabled 
                        outlined
                        v-model="leave.leave_date"></v-text-field>
                    <v-text-field dense label="Leave Type" 
                        disabled 
                        outlined
                        v-model="leave.details.leave_type"></v-text-field>
                    <v-textarea dense label="Reason"
                        disabled 
                        outlined
                        rows="2"
                        v-model="leave.details.leave_reason"></v-textarea>
                    <v-textarea dense label="Approver Remarks" 
                        :disabled="leave.details.approved == 1 || leave.details.declined == 1"
                        rows="2"
                        outlined
                        v-model="leave.details.approver_remarks"
                        hide-details></v-textarea>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text v-if="leave.details.approved == 0 && leave.details.declined == 0">
                    <div class="pt-5">
                        <v-row>
                            <v-col>
                                <v-btn color="primary" small block 
                                    :disabled="!leave.details.approver_remarks"
                                    @click="approveLeaveRequest(leave.details)">Approve</v-btn>
                            </v-col>
                            <v-col>
                                <v-btn color="error" small block 
                                    :disabled="!leave.details.approver_remarks"
                                    @click="declineLeaveRequest(leave.details)">Decline</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
export default {
    name : 'StaffLeaves',
    data() {
        return {
            month : {},
            year : {},
            months : [],
            years : [],
            show_leave_details : false,
            staff_leaves : [],
            leave : {
                name : '',
                details : {}
            },
            days : []
        }
    },
    watch : {
        month(newVal, oldVal) {
            this.refreshLeaves();
        },
        year(newVal, oldVal) {
            this.refreshLeaves();
        }
    },
    mounted() {
        this.month = moment().format('MM');
        this.year = moment().format('YYYY');

        this.years = [];
        for(let y = this.year; y >= this.year - 5; y--) {
            this.years.push({
                value : y,
                text : y
            });
        }

        this.months = [];
        for(let m = 1; m <= 12; m++) {
            let month =  m.toString().padStart(2, '0');
            let date = this.year + '-' + month + '-01';
            this.months.push({
                value : moment(date).format('MM'),
                text : moment(date).format('MMMM'),
            })
        }
        
        this.refreshLeaves();
    },
    methods: {
        refreshLeaves() {
            axios({
                url : process.env.VUE_APP_API_URL + '/staff-leaves',
                method : 'GET',
                params : {
                    month : this.month,
                    year : this.year
                },
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                let output = res.data;
                this.staff_leaves = output.data.summary;

                this.days = [];
                let days = output.data.days;
                for(let i = 1; i <= days; i++) {
                    this.days.push({
                        day : i,
                        week_day : moment(this.year + '-' + this.month + '-' + i.toString().padStart(2, '0')).format('ddd')
                    })
                }
            });
        },
        getLeaveClass(leave) {
            if(leave.approved == 0 && leave.declined == 0) {
                return 'orange';
            }
            else if(leave.approved == 1) {
                return 'green';
            }
            else if(leave.declined == 1) {
                return 'red';
            }
        },
        showLeaveDetails(name, d) {
            this.leave = {
                name : name,
                leave_date : moment(d.date).format('MMM-DD-YYYY (dddd)'),
                details : d.leave,
            };
            console.log(this.leave);
            this.show_leave_details = true;
        },
        approveLeaveRequest(leave) {
            this.$store.state.loading = true;
            leave = Object.assign({}, leave);
            leave.approved = 1;
            leave.declined = 0;
            axios({
                url : process.env.VUE_APP_API_URL + '/staff-leaves/' + leave.id,
                method : 'PUT',
                data : leave,
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                let output = res.data;
                this.$store.state.loading = false;

                this.refreshLeaves();
                this.show_leave_details = false;
            }).catch(err => {
                this.$store.state.loading = false;
            });
        },
        declineLeaveRequest(leave) {
            this.$store.state.loading = true;

            leave = Object.assign({}, leave);
            leave.approved = 0;
            leave.declined = 1;
            axios({
                url : process.env.VUE_APP_API_URL + '/staff-leaves/' + leave.id,
                method : 'PUT',
                data : leave,
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                let output = res.data;
                this.$store.state.loading = false;

                this.refreshLeaves();
                this.show_leave_details = false;
            }).catch(err => {
                this.$store.state.loading = false;
            });

        }
    }
}
</script>
<style scoped>
    .bordered-table td {
        border: 1px solid rgba(0, 0, 0, 0.12); 
    }
</style>