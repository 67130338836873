import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token : localStorage.getItem('token') || '',
    user : localStorage.getItem('user') || '',
    loading : false,
    host_url : process.env.VUE_APP_HOST_URL,
    api_url : process.env.VUE_APP_API_URL
  },
  getters: {
    getToken (state) {
      return state.token;
    },
    getUser(state) {
      return JSON.parse(state.user);
    }
  },
  mutations: {
    changeToken(state, token) {
      localStorage.setItem('token', token);
      state.token = token;
    },
    changeUser(state, user) {
      localStorage.setItem('user', JSON.stringify(user));
      state.user = JSON.stringify(user);
    },
    changeLoading(state, loading) {
      state.loading = loading;
    }
  },
  actions: {
    login({commit}, info) {
      commit('changeToken', info.token);
      commit('changeUser', info.user)
    },
    loading({commit}, loading) {
      commit('changeLoading', loading);
    }
  },
  modules: {
  }
})
